.hero {
  $this: &;

  position: relative;
  min-height: 900px;
  padding-bottom: 50px;
  background-color: #ebebeb;
  z-index: 11;

  @include sm {
    min-height: 537px;
    padding-bottom: 0;
    padding-top: 46px; }

  @include xs {
    padding-top: 75px;
    min-height: 498px; }


  &__header {
    position: relative;
    z-index: 2;
    @include sm {
      z-index: 100; } }

  &__container {
    position: relative; }

  &__image {
    @include coverdiv;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 0;
    @include sm {
      z-index: 0;
      height: calc(100% - 290px); }
    @include xs {
      height: 317px; } }

  &__cover {
    background-color: #EBEBEB;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 64.25%;
    z-index: 1;

    @include sm {
      display: none; } }

  &__content {
    max-width: 69%;
    padding-top: 85px;
    position: relative;
    z-index: 2;

    @include md {
      max-width: 65%; }
    @include sm {
      max-width: 100%; } }


  &__title {
    margin-bottom: 33px;
    @include sm {
      letter-spacing: -1px;
      margin-bottom: -28px;
      position: relative;
      z-index: 2; }
    @include xs {
      line-height: 0.86;
      margin-bottom: -23px;
      span:before {
        top: -3px;
        bottom: 2px; } } }

  &__descr {
    margin-bottom: 18px;
    max-width: 460px;
    color: #474747;

    @include r(1200) {
      max-width: 400px; }
    @include sm;
    //   font-size: 14px
    //margin-bottom: 53px
    //   line-height: 1.28
    @include xs;
    //   font-size: 12px
    //   line-height: 1.5
    //   padding: 0 2px
 }    //margin-bottom: 12px

  &__form {
    max-width: 428px;
    margin-bottom: 117px;
    @include sm {
      display: none; } }

  &__wrapper {
    @include sm {
      position: relative;
      padding-bottom: 20px;
      z-index: 1;
      min-height: 290px;
      padding-top: 43px;
      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        bottom: 0;
        top: 0;
        left: -50px;
        right: -50px;
        background-color: #fff; } }
    @include xs {
      min-height: 181px;
      padding-top: 34px; } }


  &__apps {
    @include sm {
      display: none; } }

  &__button {
    position: absolute;
    bottom: 246px;
    right: -32px;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: #645AD7;
    border: 4px solid #EBEBEB;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .icon {
      font-size: 22px;
      margin-right: -6px; } }

  &__teens {
    position: absolute;
    right: -30px;
    z-index: 3;
    top: 21px;

    img {
      max-width: 100%;
      height: auto; }

    @include md {
      width: 50%; }
    @include sm {
      display: none; } }

  &__download {
    display: none;
    margin-left: 4px;
    @include sm {
      display: block; }
    @include xs {
      margin-left: 0; } }

  &__copy {
    @include sm {
      display: none; } }
  &__card {
    display: none;
    max-width: 325px;
    margin: 0 auto -62px;
    img {
      max-width: 100%;
      height: auto; }
    @include sm {
      display: block; }
    @include xs {
      max-width: 290px;
      margin: 0 auto -72px; } }
  &--teens {

    background-color: #FF2D55;
    @include sm {
      padding-top: 37px; }

    @include xs {
      padding-top: 25px; }

    #{$this}__wrapper {
      @include sm {
        padding-top: 32px;
        min-height: 242px; }
      @include xs {
        min-height: 181px; } }

    #{$this}__title {
      letter-spacing: -2px;
      @include xs {
        letter-spacing: -1px; } }
    #{$this}__content {
      max-width: 50%;
      padding-left: 3px;
      @include sm {
        max-width: 100%; }
      @include xs {
        padding-left: 0; } }
    #{$this}__descr {
      font-size: 18px;
      line-height: 1.55;
      margin-bottom: 26px;
      @include sm {
        font-size: 18px; }
      @include xs {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 1.5; } }
    #{$this}__form {
      margin-bottom: 55px; }
    #{$this}__apps {
      margin-bottom: 66px; } }

  &--white {
    background-color: #EBEBEB; } }
