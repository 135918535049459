.prices {
  background-color: #EBEBEB;
  text-align: center;
  padding-bottom: 105px;
  padding-top: 75px;

  @include sm {
    background-color: #fff;
    padding-bottom: 137px;
    padding-top: 53px;
    text-align: left; }
  @include xs {
    padding-bottom: 117px; }

  &__title {
    margin-bottom: 74px;
    @include sm {
      margin-bottom: 12px;
      padding: 0 9px; }
    @include xs {
      margin-bottom: 25px; } }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    @include sm {
      margin: 0;
      flex-direction: column; } }
  &__item {
    width: 50%;
    padding: 0 15px;
    @include sm {
      width: 100%;
      max-width: 275px;
      margin: 0 auto;
      padding: 0 9px;
      &:not(:last-child) {
        margin-bottom: 20px; } }
    @include xs {
      padding: 0 1px;
      &:not(:last-child) {
        margin-bottom: 17px; } } } }
