.how-it-work {
  background-color: #EBEBEB;
  padding-top: 222px;
  padding-bottom: 75px;
  text-align: center;

  @include sm {
    background-color: #fff;
    padding-top: 54px;
    text-align: left;
    padding-bottom: 13px; }

  @include xs {
    padding-bottom: 2px; }

  &__title {
    margin-bottom: 89px;
    @include sm {
      padding: 0 9px;
      margin-bottom: 37px; }
    @include xs {
      margin-bottom: 25px; } }

  &__features {
    display: flex;
    justify-content: space-between;
    @include sm {
      flex-direction: column;
      align-items: center; } }

  &__feature {
    max-width: 33.33%;
    min-width: 33.33%;

    @include sm {
      min-width: 100%;
      max-width: 100%;
      &:not(:last-child) {
        margin-bottom: 41px; } } }

  &__icon {
    text-align: center;
    margin-bottom: 41px;
    .icon {
      fill: #645AD7;
      font-size: 114px; }
    @include sm {
      margin-bottom: 18px;

      .icon {
        font-size: 79px; } } }

  &__heading {
    font-size: 24px;
    line-height: 1.16;
    max-width: 250px;
    margin: 0 auto;
    color: #474747;

    @include sm {
      font-size: 18px;
      text-align: center;
      max-width: 100%; }
    @include xs {
      font-size: 14px; } } }
