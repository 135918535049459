.burger {
  height: 14px;
  width: 24px;
  position: relative;
  background-color: transparent;

  i, &:before, &:after {
    content: '';
    position: absolute;
    height: 2px;
    left: 0;
    right: 0;
    background-color: black;
    width: 100%;
    transition: .2s ease; }
  &:before {
    top: 0;
    transform-origin: right top;
    transform: rotate(0) scaleX(1); }
  i {
    top: 6px; }
  &:after {
    bottom: 0;
    transform-origin: right bottom;
    transform: rotate(0) scaleX(1); }

  span {
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px; }

  &.is-active {
    i, &:before, &:after {
      background-color: #645AD7; }
    i {
      opacity: 0; }
    &:before {
      transform: rotate(-42deg) scaleX(.78); }
    &:after {
      transform: rotate(42deg) scaleX(.78); } }

  @include xs {
    width: 20px;
    height: 12px;

    i {
        top: 5px; } } }
