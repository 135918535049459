.header {
  padding: 55px 0;
  transform: .3s ease;

  &__container {
    display: flex;
    max-width: 1213px;
    margin: 0 auto;
    padding: 0 14px;
    @include sm {
      padding: 0 16px; }
    @include xs {
      padding: 0 14px; } }

  &__burger {
    font-size: 0;
    line-height: 1;
    margin-top: -2px; }

  &__logo {
    position: relative;
    z-index: 1;
    img {
      max-width: 100%;
      height: auto; }
    @include sm {
      width: 51px;
      position: relative;
      top: 10px; }
    @include xs {
      width: 43px;
      top: 8px; } }

  &__start {
    margin-left: auto;
    padding-top: 21px;
    margin-right: 30px;
    position: relative;
    z-index: 10;
    @include sm {
      padding-top: 14px;
      margin-right: 0; }
    @include xs {
      padding-top: 9px; } }
  &__nav {
    padding-top: 24px;
    @include sm {
      display: none; } }

  &__socials {
    margin-left: auto;
    padding-top: 21px;
    padding-right: 38px;
    @include sm {
      display: none; } }

  &__wrapper {
    padding-right: 35px;
    width: 67.3%;
    display: flex;
    justify-content: space-between;
    @include r(1320) {
      width: 65%; }
    @include sm {
      flex-grow: 1;
      padding-right: 16px; } }

  &__actions {
    display: none;
    @include sm {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-top: 18px;
      height: 100%;
      z-index: 1; }
    @include xs {
      margin-top: 14px; } }
  &__page {
    color: #fff;
    font-size: 18px;
    margin-right: 27px;
    position: relative;
    top: -2px;
    font-weight: 300;
    @include xs {
      top: -1px;
      margin-right: 13px; } }

  &__menu {
    display: none;

    @include sm {
      display: block;
      transition: .3s ease;
      transform: translateX(100%);
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      &.is-showed {
        transform: translateX(0); } } }



  @include sm {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #645AD7;
    height: 50px;

    &--red {
      background-color: #FF2D55; } }
  @include xs {
    height: 42px; }

  &.menu-showed {
    background-color: black; } }
