.footer {
  background-color: black;
  padding-top: 94px;
  padding-bottom: 47px;
  position: relative;

  @include sm {
    padding-top: 45px;
    z-index: 6; }

  @include xs {
    padding-top: 39px; }

  &__container {
    display: flex;
    max-width: 1231px;
    flex-wrap: wrap;
    padding: 0 20px;
    margin: 0 auto;
    align-items: flex-end;

    @include xs {
      padding: 0 18px; } }

  &__logo {
    margin-right: 150px;
    display: block;
    img {
      max-width: 100%;
      height: auto; }
    @include r(1050) {
      margin-right: 20px; }
    @include sm {
      width: 48px; }
    @include xs {
      width: 40px; } }

  &__logo-wrapper {
    @include sm {
      width: 100%;
      margin-bottom: -3px; } }
  &__download {
    top: 5px;
    position: relative; }

  &__apps {
    margin-left: -20px;

    @include sm {
      display: none; } }

  &__wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    padding-bottom: 3px;
    padding-right: 40px;
    align-items: center;
    @include md {
      padding-right: 0; }
    @include sm {
      order: 3;
      display: inline-flex; } }

  &__download {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    @include pfblack;
    font-size: 64px;
    letter-spacing: -2px;
    line-height: 1;
    @include md {
      font-size: 42px; }
    @include sm {
      position: absolute;
      top: -18px;
      left: 50%;
      transform: translateX(-50%);
      text-transform: capitalize;
      @include pfblack;
      font-size: 18px;
      text-transform: uppercase;
      letter-spacing: 0;
      border: 3px solid black;
      background-color: #645AD7;
      padding: 10px 36px 7px;
      border-radius: 32px; } }

  &__copyrights {
    order: 4;
    margin-left: auto;
    margin-right: 41px;
    @include sm {
      order: 1;
      padding-left: 26px;
      width: auto;
      position: relative;
      margin-left: 0;
      margin-right: 0;
      top: -8px; }
    @include xs {
      padding-left: 20px; } }

  &__bottom {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between; }
  &__socials {
    @include sm {
      margin-left: auto;
      padding-right: 25px; } }
  &__nav {
    display: flex;
    margin-top: 55px;
    margin-left: 41px;
    order: 3;
    a {
      color: rgba(white, .7);
      display: inline-block;
      font-size: 12px;
      line-height: 1.17;
      transition: .3s ease;
      text-decoration: none;
      &:not(:last-child) {
        margin-right: 45px; }
      &:hover {
        color: rgba(#645AD7, .7); } }
    @include sm {
      order: 0;
      flex-direction: column;
      align-items: center;
      margin-left: 0;
      margin-top: 0;
      width: 100%;
      margin-bottom: 14px;
      a {
        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 12px; } } } } }
