.menu {
  $this: &;

  background-color: black;
  height: 100%;
  width: 100%;
  padding: 100px 45px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include xs {
    padding: 100px 38px 25px; }


  &__extra {
    display: flex;
    @include sm {
      flex-direction: column; }
    @include xs {
      flex-direction: row;
      justify-content: space-between;
      align-items: center; } }

  &__socials {
    margin-bottom: 17px;
    @include xs {
      order: 1;
      margin-bottom: 0; } }

  &__smile {
    display: inline-block;
    vertical-align: middle;
    opacity: 0;
    margin-left: 10px;
    width: 55px;
    height: 55px;
    position: relative;
    top: -3px;
    img {
      max-width: 100%;
      height: auto; }
    @include xs {
      width: 50px;
      height: 50px; } }
  &__list {
    flex-direction: column;
    display: flex;
    margin-bottom: 186px;

    @include xs {
      margin-bottom: 63px; }
    a {
      font-size: 52px;
      line-height: .81;
      color: #fff;
      @include pfblack;
      text-transform: uppercase;
      text-decoration: none;
      display: inline-block;
      &:not(:last-child) {
        margin-bottom: 58px; }
      &.is-active {
        #{$this}__smile {
          opacity: 1; } }
      @include xs {
        font-size: 42px;
        line-height: 1; }
      @include rh(550) {
        &:not(:last-child) {
            margin-bottom: 20px; } } } } }
