.form {
  $this: &;

  overflow: hidden;
  display: flex;
  height: 42px;
  position: relative;

  &__textfield {
    position: relative;
    width: calc(100% - 173px);
    border-radius: 3px 0 0 3px;
    border: 3px solid black;
    input {
      width: 100%;
      height: 100%;
      background-color: #fff;
      font-size: 19px;
      letter-spacing: 0.15px;
      color: rgba(black, .7);
      @include sfreg;
      padding: 7px 12px 8px;
      &::placeholder {
        color: rgba(black, .4);
        @include sfreg; } }
    &.mask-showed {
      #{$this}__placeholder {
        display: none; } } }
  &__placeholder {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 19px;
    padding: 7px 12px 8px;
    z-index: 1;
    background-color: #fff;
    color: rgba(black, .7); }
  &__button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;

    button {
      display: inline-block;
      background-color: black;
      padding-top: 2px;
      border-radius: 0 32px 32px 0;
      color: #fff;
      padding-top: 2px;
      letter-spacing: 1px;
      height: 100%;
      width: 173px;
      font-size: 18px;
      @include pfblack;
      text-transform: uppercase; } } }
