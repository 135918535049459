body {
	background: #FFF;
	font-size: 14px;
	@include sfreg;
	line-height: 1.16;
	&.scroll-disabled {
		overflow: hidden;
		height: 100%; } }


.out {
	overflow: hidden;
	@include sm {
		padding-top: 50px; }
	@include xs {
		padding-top: 42px; } }



.description {
	font-size: 24px;
	font-weight: 400;
	@include sm {
		font-size: 18px;
		padding: 0 9px; }
	@include xs {
		font-size: 14px;
		padding: 0 2px;
		line-height: 1.5; } }

.heading {
	font-size: 64px;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: -2px;
	font-weight: normal;
	@include pfblack;

	span {
		color: #fff;
		position: relative;
		display: inline-block;
		z-index: 1;
		&:before {
			content: '';
			position: absolute;
			top: -2px;
			right: -9px;
			bottom: 6px;
			left: -6px;
			z-index: -1;
			border-radius: 5px; } }
	@include sm {
		font-size: 52px;
		line-height: .93;
		padding: 0 9px;

		span {
			&:before {
				left: -5px;
				right: -7px;
				bottom: 5px; } } }

	@include xs {
		font-size: 42px;
		line-height: 1;
		padding: 0 2px; }

	&--blue {
		span {
			&:before {
				background-color: #645AD7; } } }

	&--white-blue {
		span {
			color: #645AD7;
			&:before {
				background-color: white; } } }
	&--white-gray {
		span {
			color: #c9c9c9;
			&:before {
				background-color: white; } } }
	&--red {
		span {
			color: #fff;
			&:before {
				background-color: #FF2D55; } } }
	&--salad {
		span {
			color: #fff;
			&:before {
				background-color: #00E6AA; } } }

	&--lighter {
		color: rgba(black, .7); }

	&--black {
		span {
			color: #fff;
			&:before {
				background-color: black; } } }

	&--min-spacing {
		@include sm {
			letter-spacing: -3px; }
		@include xs {
			letter-spacing: -2.5px; } } }

.copy {
	font-size: 12px;
	color: rgba(white, .7);
	@include xs {
		font-size: 10px; }

	&--black {
		color: rgba(#222, .7); } }

.download {
	background-color: black;
	text-decoration: none;
	color: #fff;
	font-size: 18px;
	text-transform: uppercase;
	text-align: center;
	padding: 8px 46px 6px;
	border-radius: 32px;
	@include pfblack;
	display: inline-block;
	@include xs {
		padding: 4px 17px 1px; } }


.page-header {
	background-color: #645ad7; }

.page-content {
	padding: 50px 15px;
	max-width: 600px;
	margin: 0 auto; }

.content {
	h1,h3,h2,h4 {
		@include sfreg; }
	h1 {
		margin-bottom: 30px;
		font-size: 42px;
		@include sm {
			font-size: 24px;
			margin-bottom: 20px; } }
	h2 {
		font-size: 28px;
		margin-bottom: 20px;
		@include sm {
			font-size: 20px;
			margin-bottom: 10px; } }
	h3 {
		font-size: 24px;
		margin-bottom: 20px;
		@include sm {
			font-size: 18px;
			margin-bottom: 10px; } }
	h4 {
			font-size: 20px;
			margin-bottom: 10px;
			@include sm {
				font-size: 16px;
				margin-bottom: 5px; } }
	p {
		font-size: 18px;
		&:not(:last-child) {
			margin-bottom: 20px; }
		@include sm {
			font-size: 15px;
			&:not(:last-child) {
				margin-bottom: 10px; } } }
	ul {
		margin-bottom: 20px;
		padding-left: 20px;
		list-style-type: disc;

		li {
			font-size: 18px;
			&:not(:last-child) {
				margin-bottom: 10px; }
			@include sm {
				font-size: 15px;
				&:not(:last-child) {
					margin-bottom: 5px; } } } }

	a {
		color: #645ad7; } }


.btn {
	border-radius: 32px;
	color: #fff;
	@include pfblack;
	text-transform: uppercase;
	font-size: 18px;
	min-width: 160px;
	padding: 8px 20px;
	display: inline-block;
	text-align: center;
	height: 32px;
	text-decoration: none;
	@include sm {
		background-color: #fff;
		font-size: 15px;
		height: 25px;
		padding: 5px 15px 4px;
		min-width: auto; }
	@include xs {
		font-size: 14px;
		padding: 6px 11px 4px; }


	&--primary {
		background-color: #645AD7;
		@include sm {
			color: #645AD7; } }

	&--secondary {
		background-color: #FF2D55;
		@include sm {
			color: #FF2D55; } }

	&.is-active {
		background-color: #645AD7;
		color: #fff; } }
