.price {
  $this: &;
  text-align: center;
  border-radius: 20px;
  min-height: 370px;
  padding-top: 121px;
  padding-bottom: 20px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    border-radius: 20px;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
    opacity: 0;
    transition: .3s ease; }

  @include sm {
    min-height: 195px;
    padding-top: 53px; }
  @include xs {
    padding-top: 47px;
    padding-bottom: 18px;
    min-height: 164px; }

  &:hover {
    &:before {
      opacity: 1; } }

  &__count {
    @include pfblack;
    font-size: 120px;
    line-height: 64px;
    letter-spacing: -2px;
    color: #fff;
    margin-bottom: 19px;

    @include sm {
      font-size: 72px;
      margin-bottom: 3px; }
    @include xs {
      margin-bottom: -7px; } }


  &__per {
    font-size: 42px;
    letter-spacing: -2px;
    color: #fff;
    margin-bottom: 50px;
    @include sm {
      font-size: 24px;
      line-height: 1;
      letter-spacing: 0;
      margin-bottom: 13px; }
    @include xs {
      margin-bottom: 6px; } }
  &__link {
    font-size: 18px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    @include sfreg;

    @include sm {
      font-size: 12px;
      line-height: 1; } }
  &--month {
    background-color: #645AD7;

    #{$this}__link {
      color: rgba(white, .6); } }
  &--year {
    background-color: #00E6AA;

    #{$this}__link {
      color: rgba(black, .3); } } }


