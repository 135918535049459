.nav {
  a {
    font-size: 18px;
    line-height: 1.55;
    position: relative;
    display: inline-block;
    text-decoration: none;
    transition: .3s ease;
    padding-bottom: 3px;
    color: #a5a5a5;

    &:not(:first-child) {
      margin-left: 47px; }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      transform-origin: left center;
      transform: scaleX(0);
      transition: .3s ease; } }


  &--primary {
    a {
      &:before {
        background-color: #645AD7; }
      &.is-active, &:hover {
        color: #645AD7;
        &:before {
          transform: scaleX(1); } } } }

  &--secondary {
    a {
      &:before {
        background-color: #FF2D55; }
      &.is-active, &:hover {
        color: #FF2D55;
        &:before {
          transform: scaleX(1); } } } }

  &--white {
    a {
      color: #fff;
      &:before {
        background-color: #fff; }
      &.is-active, &:hover {
        color: #fff; } } } }
