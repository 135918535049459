html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}

input[type="submit"],
button {
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
  }
}

.icon-arrow {
  width: 0.86em;
  height: 1em;
  fill: white;
}

.icon-cards {
  width: 1.07em;
  height: 1em;
  fill: #645AD7;
}

.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: none;
}

.icon-instagram {
  width: 1.21em;
  height: 1em;
  fill: initial;
}

.icon-shield-lock {
  width: 1em;
  height: 1em;
  fill: #00E6AA;
}

.icon-telegram {
  width: 1.21em;
  height: 1em;
  fill: white;
}

.icon-time {
  width: 1.11em;
  height: 1em;
  fill: #645AD7;
}

.icon-twitter {
  width: 1.21em;
  height: 1em;
  fill: white;
}

.icon-user {
  width: 1em;
  height: 1em;
  fill: #645AD7;
}

body {
  background: #FFF;
  font-size: 14px;
  font-family: 'SFCompactDisplay-Regular', sans-serif;
  line-height: 1.16;
}

body.scroll-disabled {
  overflow: hidden;
  height: 100%;
}

.out {
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .out {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 360px) {
  .out {
    padding-top: 42px;
  }
}

.description {
  font-size: 24px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .description {
    font-size: 18px;
    padding: 0 9px;
  }
}

@media only screen and (max-width: 360px) {
  .description {
    font-size: 14px;
    padding: 0 2px;
    line-height: 1.5;
  }
}

.heading {
  font-size: 64px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: -2px;
  font-weight: normal;
  font-family: 'PFHandbookPro-Black', sans-serif;
}

.heading span {
  color: #fff;
  position: relative;
  display: inline-block;
  z-index: 1;
}

.heading span:before {
  content: '';
  position: absolute;
  top: -2px;
  right: -9px;
  bottom: 6px;
  left: -6px;
  z-index: -1;
  border-radius: 5px;
}

@media only screen and (max-width: 767px) {
  .heading {
    font-size: 52px;
    line-height: .93;
    padding: 0 9px;
  }
  .heading span:before {
    left: -5px;
    right: -7px;
    bottom: 5px;
  }
}

@media only screen and (max-width: 360px) {
  .heading {
    font-size: 42px;
    line-height: 1;
    padding: 0 2px;
  }
}

.heading--blue span:before {
  background-color: #645AD7;
}

.heading--white-blue span {
  color: #645AD7;
}

.heading--white-blue span:before {
  background-color: white;
}

.heading--white-gray span {
  color: #c9c9c9;
}

.heading--white-gray span:before {
  background-color: white;
}

.heading--red span {
  color: #fff;
}

.heading--red span:before {
  background-color: #FF2D55;
}

.heading--salad span {
  color: #fff;
}

.heading--salad span:before {
  background-color: #00E6AA;
}

.heading--lighter {
  color: rgba(0, 0, 0, 0.7);
}

.heading--black span {
  color: #fff;
}

.heading--black span:before {
  background-color: black;
}

@media only screen and (max-width: 767px) {
  .heading--min-spacing {
    letter-spacing: -3px;
  }
}

@media only screen and (max-width: 360px) {
  .heading--min-spacing {
    letter-spacing: -2.5px;
  }
}

.copy {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
}

@media only screen and (max-width: 360px) {
  .copy {
    font-size: 10px;
  }
}

.copy--black {
  color: rgba(34, 34, 34, 0.7);
}

.download {
  background-color: black;
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  padding: 8px 46px 6px;
  border-radius: 32px;
  font-family: 'PFHandbookPro-Black', sans-serif;
  display: inline-block;
}

@media only screen and (max-width: 360px) {
  .download {
    padding: 4px 17px 1px;
  }
}

.page-header {
  background-color: #645ad7;
}

.page-content {
  padding: 50px 15px;
  max-width: 600px;
  margin: 0 auto;
}

.content h1, .content h3, .content h2, .content h4 {
  font-family: 'SFCompactDisplay-Regular', sans-serif;
}

.content h1 {
  margin-bottom: 30px;
  font-size: 42px;
}

@media only screen and (max-width: 767px) {
  .content h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.content h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .content h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.content h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .content h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.content h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .content h4 {
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.content p {
  font-size: 18px;
}

.content p:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .content p {
    font-size: 15px;
  }
  .content p:not(:last-child) {
    margin-bottom: 10px;
  }
}

.content ul {
  margin-bottom: 20px;
  padding-left: 20px;
  list-style-type: disc;
}

.content ul li {
  font-size: 18px;
}

.content ul li:not(:last-child) {
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .content ul li {
    font-size: 15px;
  }
  .content ul li:not(:last-child) {
    margin-bottom: 5px;
  }
}

.content a {
  color: #645ad7;
}

.btn {
  border-radius: 32px;
  color: #fff;
  font-family: 'PFHandbookPro-Black', sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  min-width: 160px;
  padding: 8px 20px;
  display: inline-block;
  text-align: center;
  height: 32px;
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .btn {
    background-color: #fff;
    font-size: 15px;
    height: 25px;
    padding: 5px 15px 4px;
    min-width: auto;
  }
}

@media only screen and (max-width: 360px) {
  .btn {
    font-size: 14px;
    padding: 6px 11px 4px;
  }
}

.btn--primary {
  background-color: #645AD7;
}

@media only screen and (max-width: 767px) {
  .btn--primary {
    color: #645AD7;
  }
}

.btn--secondary {
  background-color: #FF2D55;
}

@media only screen and (max-width: 767px) {
  .btn--secondary {
    color: #FF2D55;
  }
}

.btn.is-active {
  background-color: #645AD7;
  color: #fff;
}

.container {
  max-width: 1186px;
  margin: 0 auto;
  padding: 0 36px;
}

.show-phone {
  display: none;
}

@media only screen and (max-width: 767px) {
  .show-phone {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .hide-phone {
    display: none;
  }
}

.hero {
  position: relative;
  min-height: 900px;
  padding-bottom: 50px;
  background-color: #ebebeb;
  z-index: 11;
}

@media only screen and (max-width: 767px) {
  .hero {
    min-height: 537px;
    padding-bottom: 0;
    padding-top: 46px;
  }
}

@media only screen and (max-width: 360px) {
  .hero {
    padding-top: 75px;
    min-height: 498px;
  }
}

.hero__header {
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  .hero__header {
    z-index: 100;
  }
}

.hero__container {
  position: relative;
}

.hero__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 0;
}

@media only screen and (max-width: 767px) {
  .hero__image {
    z-index: 0;
    height: calc(100% - 290px);
  }
}

@media only screen and (max-width: 360px) {
  .hero__image {
    height: 317px;
  }
}

.hero__cover {
  background-color: #EBEBEB;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 64.25%;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .hero__cover {
    display: none;
  }
}

.hero__content {
  max-width: 69%;
  padding-top: 85px;
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 1023px) {
  .hero__content {
    max-width: 65%;
  }
}

@media only screen and (max-width: 767px) {
  .hero__content {
    max-width: 100%;
  }
}

.hero__title {
  margin-bottom: 33px;
}

@media only screen and (max-width: 767px) {
  .hero__title {
    letter-spacing: -1px;
    margin-bottom: -28px;
    position: relative;
    z-index: 2;
  }
}

@media only screen and (max-width: 360px) {
  .hero__title {
    line-height: 0.86;
    margin-bottom: -23px;
  }
  .hero__title span:before {
    top: -3px;
    bottom: 2px;
  }
}

.hero__descr {
  margin-bottom: 18px;
  max-width: 460px;
  color: #474747;
}

@media only screen and (max-width: 1200px) {
  .hero__descr {
    max-width: 400px;
  }
}

.hero__form {
  max-width: 428px;
  margin-bottom: 117px;
}

@media only screen and (max-width: 767px) {
  .hero__form {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .hero__wrapper {
    position: relative;
    padding-bottom: 20px;
    z-index: 1;
    min-height: 290px;
    padding-top: 43px;
  }
  .hero__wrapper:before {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
    top: 0;
    left: -50px;
    right: -50px;
    background-color: #fff;
  }
}

@media only screen and (max-width: 360px) {
  .hero__wrapper {
    min-height: 181px;
    padding-top: 34px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__apps {
    display: none;
  }
}

.hero__button {
  position: absolute;
  bottom: 246px;
  right: -32px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: #645AD7;
  border: 4px solid #EBEBEB;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.hero__button .icon {
  font-size: 22px;
  margin-right: -6px;
}

.hero__teens {
  position: absolute;
  right: -30px;
  z-index: 3;
  top: 21px;
}

.hero__teens img {
  max-width: 100%;
  height: auto;
}

@media only screen and (max-width: 1023px) {
  .hero__teens {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .hero__teens {
    display: none;
  }
}

.hero__download {
  display: none;
  margin-left: 4px;
}

@media only screen and (max-width: 767px) {
  .hero__download {
    display: block;
  }
}

@media only screen and (max-width: 360px) {
  .hero__download {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .hero__copy {
    display: none;
  }
}

.hero__card {
  display: none;
  max-width: 325px;
  margin: 0 auto -62px;
}

.hero__card img {
  max-width: 100%;
  height: auto;
}

@media only screen and (max-width: 767px) {
  .hero__card {
    display: block;
  }
}

@media only screen and (max-width: 360px) {
  .hero__card {
    max-width: 290px;
    margin: 0 auto -72px;
  }
}

.hero--teens {
  background-color: #FF2D55;
}

@media only screen and (max-width: 767px) {
  .hero--teens {
    padding-top: 37px;
  }
}

@media only screen and (max-width: 360px) {
  .hero--teens {
    padding-top: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .hero--teens .hero__wrapper {
    padding-top: 32px;
    min-height: 242px;
  }
}

@media only screen and (max-width: 360px) {
  .hero--teens .hero__wrapper {
    min-height: 181px;
  }
}

.hero--teens .hero__title {
  letter-spacing: -2px;
}

@media only screen and (max-width: 360px) {
  .hero--teens .hero__title {
    letter-spacing: -1px;
  }
}

.hero--teens .hero__content {
  max-width: 50%;
  padding-left: 3px;
}

@media only screen and (max-width: 767px) {
  .hero--teens .hero__content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 360px) {
  .hero--teens .hero__content {
    padding-left: 0;
  }
}

.hero--teens .hero__descr {
  font-size: 18px;
  line-height: 1.55;
  margin-bottom: 26px;
}

@media only screen and (max-width: 767px) {
  .hero--teens .hero__descr {
    font-size: 18px;
  }
}

@media only screen and (max-width: 360px) {
  .hero--teens .hero__descr {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 1.5;
  }
}

.hero--teens .hero__form {
  margin-bottom: 55px;
}

.hero--teens .hero__apps {
  margin-bottom: 66px;
}

.hero--white {
  background-color: #EBEBEB;
}

.header {
  padding: 55px 0;
  transform: .3s ease;
}

.header__container {
  display: flex;
  max-width: 1213px;
  margin: 0 auto;
  padding: 0 14px;
}

@media only screen and (max-width: 767px) {
  .header__container {
    padding: 0 16px;
  }
}

@media only screen and (max-width: 360px) {
  .header__container {
    padding: 0 14px;
  }
}

.header__burger {
  font-size: 0;
  line-height: 1;
  margin-top: -2px;
}

.header__logo {
  position: relative;
  z-index: 1;
}

.header__logo img {
  max-width: 100%;
  height: auto;
}

@media only screen and (max-width: 767px) {
  .header__logo {
    width: 51px;
    position: relative;
    top: 10px;
  }
}

@media only screen and (max-width: 360px) {
  .header__logo {
    width: 43px;
    top: 8px;
  }
}

.header__start {
  margin-left: auto;
  padding-top: 21px;
  margin-right: 30px;
  position: relative;
  z-index: 10;
}

@media only screen and (max-width: 767px) {
  .header__start {
    padding-top: 14px;
    margin-right: 0;
  }
}

@media only screen and (max-width: 360px) {
  .header__start {
    padding-top: 9px;
  }
}

.header__nav {
  padding-top: 24px;
}

@media only screen and (max-width: 767px) {
  .header__nav {
    display: none;
  }
}

.header__socials {
  margin-left: auto;
  padding-top: 21px;
  padding-right: 38px;
}

@media only screen and (max-width: 767px) {
  .header__socials {
    display: none;
  }
}

.header__wrapper {
  padding-right: 35px;
  width: 67.3%;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1320px) {
  .header__wrapper {
    width: 65%;
  }
}

@media only screen and (max-width: 767px) {
  .header__wrapper {
    flex-grow: 1;
    padding-right: 16px;
  }
}

.header__actions {
  display: none;
}

@media only screen and (max-width: 767px) {
  .header__actions {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-top: 18px;
    height: 100%;
    z-index: 1;
  }
}

@media only screen and (max-width: 360px) {
  .header__actions {
    margin-top: 14px;
  }
}

.header__page {
  color: #fff;
  font-size: 18px;
  margin-right: 27px;
  position: relative;
  top: -2px;
  font-weight: 300;
}

@media only screen and (max-width: 360px) {
  .header__page {
    top: -1px;
    margin-right: 13px;
  }
}

.header__menu {
  display: none;
}

@media only screen and (max-width: 767px) {
  .header__menu {
    display: block;
    transition: .3s ease;
    transform: translateX(100%);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .header__menu.is-showed {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 767px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #645AD7;
    height: 50px;
  }
  .header--red {
    background-color: #FF2D55;
  }
}

@media only screen and (max-width: 360px) {
  .header {
    height: 42px;
  }
}

.header.menu-showed {
  background-color: black;
}

.how-it-work {
  background-color: #EBEBEB;
  padding-top: 222px;
  padding-bottom: 75px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .how-it-work {
    background-color: #fff;
    padding-top: 54px;
    text-align: left;
    padding-bottom: 13px;
  }
}

@media only screen and (max-width: 360px) {
  .how-it-work {
    padding-bottom: 2px;
  }
}

.how-it-work__title {
  margin-bottom: 89px;
}

@media only screen and (max-width: 767px) {
  .how-it-work__title {
    padding: 0 9px;
    margin-bottom: 37px;
  }
}

@media only screen and (max-width: 360px) {
  .how-it-work__title {
    margin-bottom: 25px;
  }
}

.how-it-work__features {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .how-it-work__features {
    flex-direction: column;
    align-items: center;
  }
}

.how-it-work__feature {
  max-width: 33.33%;
  min-width: 33.33%;
}

@media only screen and (max-width: 767px) {
  .how-it-work__feature {
    min-width: 100%;
    max-width: 100%;
  }
  .how-it-work__feature:not(:last-child) {
    margin-bottom: 41px;
  }
}

.how-it-work__icon {
  text-align: center;
  margin-bottom: 41px;
}

.how-it-work__icon .icon {
  fill: #645AD7;
  font-size: 114px;
}

@media only screen and (max-width: 767px) {
  .how-it-work__icon {
    margin-bottom: 18px;
  }
  .how-it-work__icon .icon {
    font-size: 79px;
  }
}

.how-it-work__heading {
  font-size: 24px;
  line-height: 1.16;
  max-width: 250px;
  margin: 0 auto;
  color: #474747;
}

@media only screen and (max-width: 767px) {
  .how-it-work__heading {
    font-size: 18px;
    text-align: center;
    max-width: 100%;
  }
}

@media only screen and (max-width: 360px) {
  .how-it-work__heading {
    font-size: 14px;
  }
}

.prices {
  background-color: #EBEBEB;
  text-align: center;
  padding-bottom: 105px;
  padding-top: 75px;
}

@media only screen and (max-width: 767px) {
  .prices {
    background-color: #fff;
    padding-bottom: 137px;
    padding-top: 53px;
    text-align: left;
  }
}

@media only screen and (max-width: 360px) {
  .prices {
    padding-bottom: 117px;
  }
}

.prices__title {
  margin-bottom: 74px;
}

@media only screen and (max-width: 767px) {
  .prices__title {
    margin-bottom: 12px;
    padding: 0 9px;
  }
}

@media only screen and (max-width: 360px) {
  .prices__title {
    margin-bottom: 25px;
  }
}

.prices__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

@media only screen and (max-width: 767px) {
  .prices__list {
    margin: 0;
    flex-direction: column;
  }
}

.prices__item {
  width: 50%;
  padding: 0 15px;
}

@media only screen and (max-width: 767px) {
  .prices__item {
    width: 100%;
    max-width: 275px;
    margin: 0 auto;
    padding: 0 9px;
  }
  .prices__item:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 360px) {
  .prices__item {
    padding: 0 1px;
  }
  .prices__item:not(:last-child) {
    margin-bottom: 17px;
  }
}

.footer {
  background-color: black;
  padding-top: 94px;
  padding-bottom: 47px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .footer {
    padding-top: 45px;
    z-index: 6;
  }
}

@media only screen and (max-width: 360px) {
  .footer {
    padding-top: 39px;
  }
}

.footer__container {
  display: flex;
  max-width: 1231px;
  flex-wrap: wrap;
  padding: 0 20px;
  margin: 0 auto;
  align-items: flex-end;
}

@media only screen and (max-width: 360px) {
  .footer__container {
    padding: 0 18px;
  }
}

.footer__logo {
  margin-right: 150px;
  display: block;
}

.footer__logo img {
  max-width: 100%;
  height: auto;
}

@media only screen and (max-width: 1050px) {
  .footer__logo {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__logo {
    width: 48px;
  }
}

@media only screen and (max-width: 360px) {
  .footer__logo {
    width: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__logo-wrapper {
    width: 100%;
    margin-bottom: -3px;
  }
}

.footer__download {
  top: 5px;
  position: relative;
}

.footer__apps {
  margin-left: -20px;
}

@media only screen and (max-width: 767px) {
  .footer__apps {
    display: none;
  }
}

.footer__wrapper {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  padding-bottom: 3px;
  padding-right: 40px;
  align-items: center;
}

@media only screen and (max-width: 1023px) {
  .footer__wrapper {
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer__wrapper {
    order: 3;
    display: inline-flex;
  }
}

.footer__download {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-family: 'PFHandbookPro-Black', sans-serif;
  font-size: 64px;
  letter-spacing: -2px;
  line-height: 1;
}

@media only screen and (max-width: 1023px) {
  .footer__download {
    font-size: 42px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__download {
    position: absolute;
    top: -18px;
    left: 50%;
    transform: translateX(-50%);
    text-transform: capitalize;
    font-family: 'PFHandbookPro-Black', sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0;
    border: 3px solid black;
    background-color: #645AD7;
    padding: 10px 36px 7px;
    border-radius: 32px;
  }
}

.footer__copyrights {
  order: 4;
  margin-left: auto;
  margin-right: 41px;
}

@media only screen and (max-width: 767px) {
  .footer__copyrights {
    order: 1;
    padding-left: 26px;
    width: auto;
    position: relative;
    margin-left: 0;
    margin-right: 0;
    top: -8px;
  }
}

@media only screen and (max-width: 360px) {
  .footer__copyrights {
    padding-left: 20px;
  }
}

.footer__bottom {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .footer__socials {
    margin-left: auto;
    padding-right: 25px;
  }
}

.footer__nav {
  display: flex;
  margin-top: 55px;
  margin-left: 41px;
  order: 3;
}

.footer__nav a {
  color: rgba(255, 255, 255, 0.7);
  display: inline-block;
  font-size: 12px;
  line-height: 1.17;
  transition: .3s ease;
  text-decoration: none;
}

.footer__nav a:not(:last-child) {
  margin-right: 45px;
}

.footer__nav a:hover {
  color: rgba(100, 90, 215, 0.7);
}

@media only screen and (max-width: 767px) {
  .footer__nav {
    order: 0;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    margin-top: 0;
    width: 100%;
    margin-bottom: 14px;
  }
  .footer__nav a:not(:last-child) {
    margin-right: 0;
    margin-bottom: 12px;
  }
}

.nav a {
  font-size: 18px;
  line-height: 1.55;
  position: relative;
  display: inline-block;
  text-decoration: none;
  transition: .3s ease;
  padding-bottom: 3px;
  color: #a5a5a5;
}

.nav a:not(:first-child) {
  margin-left: 47px;
}

.nav a:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  transform-origin: left center;
  transform: scaleX(0);
  transition: .3s ease;
}

.nav--primary a:before {
  background-color: #645AD7;
}

.nav--primary a.is-active, .nav--primary a:hover {
  color: #645AD7;
}

.nav--primary a.is-active:before, .nav--primary a:hover:before {
  transform: scaleX(1);
}

.nav--secondary a:before {
  background-color: #FF2D55;
}

.nav--secondary a.is-active, .nav--secondary a:hover {
  color: #FF2D55;
}

.nav--secondary a.is-active:before, .nav--secondary a:hover:before {
  transform: scaleX(1);
}

.nav--white a {
  color: #fff;
}

.nav--white a:before {
  background-color: #fff;
}

.nav--white a.is-active, .nav--white a:hover {
  color: #fff;
}

.socials {
  display: flex;
}

.socials a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

@media only screen and (max-width: 360px) {
  .socials a {
    width: 26px;
    height: 26px;
  }
}

.socials a .icon {
  transition: .3s ease;
}

@media only screen and (max-width: 360px) {
  .socials a .icon {
    font-size: 12px;
  }
}

.socials a:not(:last-child) {
  margin-right: 20px;
}

@media only screen and (max-width: 360px) {
  .socials a:not(:last-child) {
    margin-right: 6px;
  }
}

.socials a:hover {
  background-color: #645AD7;
  transition: .3s ease;
}

.socials a:hover .icon {
  fill: #fff;
}

.socials--primary a {
  background-color: black;
}

.socials--primary a .icon {
  fill: white;
}

.socials--secondary a {
  background-color: #393939;
}

.socials--secondary a .icon {
  fill: black;
}

@media only screen and (max-width: 767px) {
  .socials--secondary a:not(:last-child) {
    margin-right: 11px;
  }
}

.socials--white a .icon {
  fill: #fff;
}

.apps {
  font-size: 0;
}

.apps__link {
  text-decoration: none;
  display: inline-block;
  font-size: 0;
}

.apps__link:not(:last-child) {
  margin-right: 25px;
}

.phone {
  position: relative;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.16);
  width: 343px;
  border-radius: 48px;
  border-style: solid;
  border-width: 15px;
  font-size: 0;
  overflow: hidden;
}

@media only screen and (max-width: 1023px) {
  .phone {
    width: 280px;
  }
}

@media only screen and (max-width: 767px) {
  .phone {
    width: 250px;
    margin: 0 auto;
    border-radius: 38px;
    border-width: 12px;
  }
}

@media only screen and (max-width: 360px) {
  .phone {
    width: 214px;
    border-width: 9px;
    border-radius: 32px;
  }
}

.phone:before {
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -83px;
  width: 167px;
  height: 24px;
  border-radius: 0 0 68px 68px;
}

@media only screen and (max-width: 1023px) {
  .phone:before {
    width: 130px;
    margin-left: -65px;
  }
}

@media only screen and (max-width: 767px) {
  .phone:before {
    height: 17px;
    width: 122px;
    margin-left: -61px;
  }
}

@media only screen and (max-width: 360px) {
  .phone:before {
    height: 15px;
    width: 104px;
    margin-left: -52px;
  }
}

.phone img {
  max-width: 100%;
  height: auto;
}

.phone--white {
  border-color: #fff;
}

.phone--white:before {
  background-color: #fff;
}

.phone--black {
  border-color: black;
}

.phone--black:before {
  background-color: black;
}

.form {
  overflow: hidden;
  display: flex;
  height: 42px;
  position: relative;
}

.form__textfield {
  position: relative;
  width: calc(100% - 173px);
  border-radius: 3px 0 0 3px;
  border: 3px solid black;
}

.form__textfield input {
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-size: 19px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.7);
  font-family: 'SFCompactDisplay-Regular', sans-serif;
  padding: 7px 12px 8px;
}

.form__textfield input::placeholder {
  color: rgba(0, 0, 0, 0.4);
  font-family: 'SFCompactDisplay-Regular', sans-serif;
}

.form__textfield.mask-showed .form__placeholder {
  display: none;
}

.form__placeholder {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 19px;
  padding: 7px 12px 8px;
  z-index: 1;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.7);
}

.form__button {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.form__button button {
  display: inline-block;
  background-color: black;
  padding-top: 2px;
  border-radius: 0 32px 32px 0;
  color: #fff;
  padding-top: 2px;
  letter-spacing: 1px;
  height: 100%;
  width: 173px;
  font-size: 18px;
  font-family: 'PFHandbookPro-Black', sans-serif;
  text-transform: uppercase;
}

.price {
  text-align: center;
  border-radius: 20px;
  min-height: 370px;
  padding-top: 121px;
  padding-bottom: 20px;
  position: relative;
}

.price:before {
  content: '';
  position: absolute;
  top: 0;
  border-radius: 20px;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
  opacity: 0;
  transition: .3s ease;
}

@media only screen and (max-width: 767px) {
  .price {
    min-height: 195px;
    padding-top: 53px;
  }
}

@media only screen and (max-width: 360px) {
  .price {
    padding-top: 47px;
    padding-bottom: 18px;
    min-height: 164px;
  }
}

.price:hover:before {
  opacity: 1;
}

.price__count {
  font-family: 'PFHandbookPro-Black', sans-serif;
  font-size: 120px;
  line-height: 64px;
  letter-spacing: -2px;
  color: #fff;
  margin-bottom: 19px;
}

@media only screen and (max-width: 767px) {
  .price__count {
    font-size: 72px;
    margin-bottom: 3px;
  }
}

@media only screen and (max-width: 360px) {
  .price__count {
    margin-bottom: -7px;
  }
}

.price__per {
  font-size: 42px;
  letter-spacing: -2px;
  color: #fff;
  margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .price__per {
    font-size: 24px;
    line-height: 1;
    letter-spacing: 0;
    margin-bottom: 13px;
  }
}

@media only screen and (max-width: 360px) {
  .price__per {
    margin-bottom: 6px;
  }
}

.price__link {
  font-size: 18px;
  font-family: 'SFCompactDisplay-Regular', sans-serif;
}

.price__link:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 767px) {
  .price__link {
    font-size: 12px;
    line-height: 1;
  }
}

.price--month {
  background-color: #645AD7;
}

.price--month .price__link {
  color: rgba(255, 255, 255, 0.6);
}

.price--year {
  background-color: #00E6AA;
}

.price--year .price__link {
  color: rgba(0, 0, 0, 0.3);
}

.features {
  position: relative;
  display: flex;
  flex-direction: column;
}

.features__container {
  position: relative;
  width: 100%;
}

.features__card {
  position: absolute;
  bottom: 130px;
  right: 192px;
  width: 477px;
  height: 320px;
}

@media only screen and (max-width: 1100px) {
  .features__card {
    right: 100px;
  }
}

@media only screen and (max-width: 1023px) {
  .features__card {
    width: 250px;
    right: 200px;
    bottom: 0;
  }
  .features__card img {
    max-width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .features__card {
    display: none;
  }
}

.features__illistration {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 1186px;
  margin: 0 auto;
  z-index: 1;
  pointer-events: none;
}

.features__image {
  position: absolute;
  bottom: 150px;
  right: 62px;
}

.features__image img {
  max-width: 100%;
  height: auto;
}

.features__row {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.features__row--blue {
  background-color: #645AD7;
}

.features__row--small {
  min-height: 450px;
}

.features__row--middle {
  min-height: 600px;
}

.features__row--big {
  min-height: 900px;
}

.features__row--semi-middle {
  min-height: 700px;
}

.features__row--gray {
  background-color: #c9c9c9;
}

.features__row--red {
  background-color: #FF2D55;
}

.features__row--yellow {
  background-color: #FAFA1E;
}

.features__row--light-gray {
  background-color: #ebebeb;
}

.features__row--green {
  background-color: #00E6AA;
}

.features__row--bottom {
  justify-content: flex-end;
  padding-bottom: 120px;
}

.features__row--top-gutter {
  padding-top: 188px;
}

@media only screen and (max-width: 767px) {
  .features__row--mobile-top {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 767px) {
  .features__row--mobile-bottom {
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 767px) {
  .features__row--allowence {
    padding-top: 49px;
    min-height: 459px;
  }
}

@media only screen and (max-width: 360px) {
  .features__row--allowence {
    min-height: 403px;
    padding-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .features__row--transparency {
    min-height: 459px;
    padding-top: 300px;
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 360px) {
  .features__row--transparency {
    min-height: 380px;
    padding-top: 250px;
    padding-bottom: 52px;
  }
}

@media only screen and (max-width: 767px) {
  .features__row--flexible {
    padding-top: 48px;
    min-height: 519px;
  }
}

@media only screen and (max-width: 360px) {
  .features__row--flexible {
    min-height: 403px;
    padding-top: 41px;
  }
}

@media only screen and (max-width: 767px) {
  .features__row--split {
    min-height: 1118px;
    padding-bottom: 55px;
  }
}

@media only screen and (max-width: 360px) {
  .features__row--split {
    min-height: 970px;
    padding-bottom: 43px;
  }
}

@media only screen and (max-width: 767px) {
  .features__row--shop {
    min-height: 608px;
    padding-top: 48px;
  }
}

@media only screen and (max-width: 360px) {
  .features__row--shop {
    padding-top: 42px;
    min-height: 523px;
  }
}

@media only screen and (max-width: 767px) {
  .features__row--share {
    min-height: 769px;
    padding-top: 125px;
  }
  .features__row--share .feature__smile {
    right: -71px;
    margin-top: -27px;
  }
}

@media only screen and (max-width: 360px) {
  .features__row--share {
    min-height: 665px;
    padding-top: 102px;
  }
  .features__row--share .feature__smile {
    right: -78px;
  }
}

@media only screen and (max-width: 767px) {
  .features__row--earn {
    min-height: 650px;
    padding-top: 105px;
  }
  .features__row--earn .feature__heading {
    margin-bottom: 16px;
  }
  .features__row--earn .feature__descr {
    max-width: 250px;
  }
}

@media only screen and (max-width: 360px) {
  .features__row--earn {
    min-height: 523px;
    padding-top: 86px;
  }
}

@media only screen and (max-width: 767px) {
  .features__row--add {
    min-height: 662px;
    padding-top: 156px;
  }
}

@media only screen and (max-width: 360px) {
  .features__row--add {
    min-height: 588px;
    padding-top: 147px;
  }
}

@media only screen and (max-width: 767px) {
  .features__row--donate {
    min-height: 659px;
    padding-top: 222px;
  }
}

@media only screen and (max-width: 360px) {
  .features__row--donate {
    min-height: 582px;
    padding-top: 186px;
  }
}

.features__phone-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 1186px;
  margin: 0 auto;
  z-index: 1;
  pointer-events: none;
}

.features__phone {
  position: absolute;
}

.features__phone--small-gutter {
  top: 100px;
}

.features__phone--middle-gutter {
  top: 165px;
}

@media only screen and (max-width: 767px) {
  .features__phone {
    left: 50% !important;
    transform: translateX(-50%) !important;
    right: auto !important;
  }
}

.features--left .features__phone {
  right: 214px;
}

@media only screen and (max-width: 1023px) {
  .features--left .features__phone {
    right: 20px;
  }
}

.features--right .features__phone {
  left: 103px;
}

@media only screen and (max-width: 1023px) {
  .features--right .features__phone {
    left: 20px;
  }
}

.features--left-bigger .features__phone {
  right: 79px;
}

@media only screen and (max-width: 1023px) {
  .features--left-bigger .features__phone {
    right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .features--allowence .features__phone {
    top: 200px;
  }
}

@media only screen and (max-width: 360px) {
  .features--allowence .features__phone {
    top: 177px;
  }
}

@media only screen and (max-width: 767px) {
  .features--flexible .features__phone {
    top: 220px;
  }
}

@media only screen and (max-width: 360px) {
  .features--flexible .features__phone {
    top: 177px;
  }
}

@media only screen and (max-width: 767px) {
  .features--shop .features__phone {
    top: 163px;
  }
}

@media only screen and (max-width: 360px) {
  .features--shop .features__phone {
    top: 141px;
  }
}

@media only screen and (max-width: 767px) {
  .features--share .features__phone {
    top: 302px;
  }
}

@media only screen and (max-width: 360px) {
  .features--share .features__phone {
    top: 265px;
  }
}

@media only screen and (max-width: 767px) {
  .features--earn .features__phone {
    top: 235px;
  }
}

@media only screen and (max-width: 360px) {
  .features--earn .features__phone {
    top: 185px;
  }
}

@media only screen and (max-width: 767px) {
  .features--add .features__phone {
    top: 310px;
  }
}

@media only screen and (max-width: 360px) {
  .features--add .features__phone {
    top: 286px;
  }
}

@media only screen and (max-width: 767px) {
  .features--donate .features__image {
    max-width: 260px;
    right: auto;
    bottom: 150px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 360px) {
  .features--donate .features__image {
    bottom: 122px;
  }
}

.feature {
  max-width: 50%;
}

@media only screen and (max-width: 767px) {
  .feature {
    max-width: 100%;
  }
}

.feature__heading {
  margin-bottom: 30px;
  position: relative;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .feature__heading {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 360px) {
  .feature__heading {
    margin-bottom: 10px;
  }
}

.feature__smile {
  position: absolute;
  top: 50%;
  margin-top: -49px;
  right: -138px;
  font-size: 0;
}

.feature__smile img {
  max-width: 100%;
  height: auto;
}

@media only screen and (max-width: 1023px) {
  .feature__smile {
    width: 50px;
    height: 50px;
    right: -70px;
    margin-top: -30px;
  }
}

@media only screen and (max-width: 767px) {
  .feature__smile {
    right: -76px;
  }
}

@media only screen and (max-width: 360px) {
  .feature__smile {
    right: -87px;
  }
}

.feature__descr {
  line-height: 1.16;
  color: rgba(0, 0, 0, 0.7);
}

.feature__descr--white {
  color: rgba(255, 255, 255, 0.7);
}

.feature__smile .icon {
  color: #121212;
}

.feature--left {
  margin-right: auto;
}

.feature--right {
  margin-left: auto;
}

.feature:not(:last-child) {
  margin-bottom: 200px;
}

@media only screen and (max-width: 767px) {
  .feature:not(:last-child) {
    margin-bottom: 57px;
  }
}

@media only screen and (max-width: 360px) {
  .feature:not(:last-child) {
    margin-bottom: 46px;
  }
}

.burger {
  height: 14px;
  width: 24px;
  position: relative;
  background-color: transparent;
}

.burger i, .burger:before, .burger:after {
  content: '';
  position: absolute;
  height: 2px;
  left: 0;
  right: 0;
  background-color: black;
  width: 100%;
  transition: .2s ease;
}

.burger:before {
  top: 0;
  transform-origin: right top;
  transform: rotate(0) scaleX(1);
}

.burger i {
  top: 6px;
}

.burger:after {
  bottom: 0;
  transform-origin: right bottom;
  transform: rotate(0) scaleX(1);
}

.burger span {
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
}

.burger.is-active i, .burger.is-active:before, .burger.is-active:after {
  background-color: #645AD7;
}

.burger.is-active i {
  opacity: 0;
}

.burger.is-active:before {
  transform: rotate(-42deg) scaleX(0.78);
}

.burger.is-active:after {
  transform: rotate(42deg) scaleX(0.78);
}

@media only screen and (max-width: 360px) {
  .burger {
    width: 20px;
    height: 12px;
  }
  .burger i {
    top: 5px;
  }
}

.menu {
  background-color: black;
  height: 100%;
  width: 100%;
  padding: 100px 45px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 360px) {
  .menu {
    padding: 100px 38px 25px;
  }
}

.menu__extra {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .menu__extra {
    flex-direction: column;
  }
}

@media only screen and (max-width: 360px) {
  .menu__extra {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.menu__socials {
  margin-bottom: 17px;
}

@media only screen and (max-width: 360px) {
  .menu__socials {
    order: 1;
    margin-bottom: 0;
  }
}

.menu__smile {
  display: inline-block;
  vertical-align: middle;
  opacity: 0;
  margin-left: 10px;
  width: 55px;
  height: 55px;
  position: relative;
  top: -3px;
}

.menu__smile img {
  max-width: 100%;
  height: auto;
}

@media only screen and (max-width: 360px) {
  .menu__smile {
    width: 50px;
    height: 50px;
  }
}

.menu__list {
  flex-direction: column;
  display: flex;
  margin-bottom: 186px;
}

@media only screen and (max-width: 360px) {
  .menu__list {
    margin-bottom: 63px;
  }
}

.menu__list a {
  font-size: 52px;
  line-height: .81;
  color: #fff;
  font-family: 'PFHandbookPro-Black', sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
}

.menu__list a:not(:last-child) {
  margin-bottom: 58px;
}

.menu__list a.is-active .menu__smile {
  opacity: 1;
}

@media only screen and (max-width: 360px) {
  .menu__list a {
    font-size: 42px;
    line-height: 1;
  }
}

@media only screen and (max-height: 550px) {
  .menu__list a:not(:last-child) {
    margin-bottom: 20px;
  }
}
