.features {
  $this: &;

  position: relative;
  display: flex;
  flex-direction: column;
  &__container {
    position: relative;
    width: 100%; }

  &__card {
    position: absolute;
    bottom: 130px;
    right: 192px;
    width: 477px;
    height: 320px;

    @include r(1100) {
      right: 100px; }
    @include md {
      width: 250px;
      right: 200px;
      bottom: 0;
      img {
        max-width: 100%;
        height: auto; } }
    @include sm {
      display: none; } }


  &__illistration {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 1186px;
    margin: 0 auto;
    z-index: 1;
    pointer-events: none; }

  &__image {
    position: absolute;
    bottom: 150px;
    right: 62px;
    img {
      max-width: 100%;
      height: auto; } }

  &__row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &--blue {
      background-color: #645AD7; }
    &--small {
      min-height: 450px; }
    &--middle {
      min-height: 600px; }
    &--big {
      min-height: 900px; }
    &--semi-middle {
      min-height: 700px; }
    &--gray {
      background-color: #c9c9c9; }
    &--red {
      background-color: #FF2D55; }
    &--yellow {
      background-color: #FAFA1E; }
    &--light-gray {
      background-color: #ebebeb; }
    &--green {
      background-color: #00E6AA; }
    &--bottom {
      justify-content: flex-end;
      padding-bottom: 120px; }
    &--top-gutter {
      padding-top: 188px; }

    &--mobile-top {
      @include sm {
        justify-content: flex-start; } }
    &--mobile-bottom {
      @include sm {
        justify-content: flex-end; } }

    &--allowence {
      @include sm {
        padding-top: 49px;
        min-height: 459px; }
      @include xs {
        min-height: 403px;
        padding-top: 40px; } }

    &--transparency {
      @include sm {
        min-height: 459px;
        padding-top: 300px;
        padding-bottom: 75px; }
      @include xs {
        min-height: 380px;
        padding-top: 250px;
        padding-bottom: 52px; } }

    &--flexible {
      @include sm {
        padding-top: 48px;
        min-height: 519px; }
      @include xs {
        min-height: 403px;
        padding-top: 41px; } }
    &--split {
      @include sm {
        min-height: 1118px;
        padding-bottom: 55px; }
      @include xs {
        min-height: 970px;
        padding-bottom: 43px; } }

    &--shop {
      @include sm {
        min-height: 608px;
        padding-top: 48px; }
      @include xs {
        padding-top: 42px;
        min-height: 523px; } }


    &--share {
      @include sm {
        min-height: 769px;
        padding-top: 125px;

        .feature__smile {
          right: -71px;
          margin-top: -27px; } }
      @include xs {
        min-height: 665px;
        padding-top: 102px;

        .feature__smile {
          right: -78px; } } }



    &--earn {
      @include sm {
        min-height: 650px;
        padding-top: 105px;
        .feature__heading {
          margin-bottom: 16px; }

        .feature__descr {
          max-width: 250px; } }
      @include xs {
        min-height: 523px;
        padding-top: 86px; } }

    &--add {
      @include sm {
        min-height: 662px;
        padding-top: 156px; }
      @include xs {
        min-height: 588px;
        padding-top: 147px; } }

    &--donate {
      @include sm {
        min-height: 659px;
        padding-top: 222px; }
      @include xs {
        min-height: 582px;
        padding-top: 186px; } } }


  &__phone-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 1186px;
    margin: 0 auto;
    z-index: 1;
    pointer-events: none; }

  &__phone {
    position: absolute;

    &--small-gutter {
      top: 100px; }
    &--middle-gutter {
      top: 165px; }

    @include sm {
      left: 50% !important;
      transform: translateX(-50%) !important;
      right: auto !important; } }

  &--left {
    #{$this}__phone {
      right: 214px;

      @include md {
        right: 20px; } } }

  &--right {
    #{$this}__phone {
      left: 103px;
      @include md {
        left: 20px; } } }

  &--left-bigger {
    #{$this}__phone {
      right: 79px;
      @include md {
        right: 20px; } } }

  &--allowence {
    @include sm {
      #{$this}__phone {
        top: 200px; } }
    @include xs {
      #{$this}__phone {
        top: 177px; } } }

  &--flexible {
    @include sm {
      #{$this}__phone {
        top: 220px; } }
    @include xs {
      #{$this}__phone {
        top: 177px; } } }
  &--shop {
    @include sm {
      #{$this}__phone {
        top: 163px; } }
    @include xs {
      #{$this}__phone {
        top: 141px; } } }

  &--share {
    @include sm {
      #{$this}__phone {
        top: 302px; } }
    @include xs {
      #{$this}__phone {
        top: 265px; } } }

  &--earn {
    @include sm {
      #{$this}__phone {
        top: 235px; } }
    @include xs {
      #{$this}__phone {
        top: 185px; } } }


  &--add {
    @include sm {
      #{$this}__phone {
        top: 310px; } }
    @include xs {
      #{$this}__phone {
        top: 286px; } } }

  &--donate {
    @include sm {
      #{$this}__image {
        max-width: 260px;
        right: auto;
        bottom: 150px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%); } }
    @include xs {
      #{$this}__image {
        bottom: 122px; } } } }




.feature {
  max-width: 50%;
  @include sm {
    max-width: 100%; }
  &__heading {
    margin-bottom: 30px;
    position: relative;
    display: inline-block;
    @include sm {
      margin-bottom: 12px; }
    @include xs {
      margin-bottom: 10px; } }

  &__smile {
    position: absolute;
    top: 50%;
    margin-top: -49px;
    right: -138px;
    font-size: 0;
    img {
      max-width: 100%;
      height: auto; }

    @include md {
      width: 50px;
      height: 50px;
      right: -70px;
      margin-top: -30px; }
    @include sm {
      right: -76px; }
    @include xs {
      right: -87px; } }
  &__descr {
    line-height: 1.16;
    color: rgba(black, .7);

    &--white {
      color: rgba(white, .7); } }

  &__smile {
    .icon {
      color: #121212; } }

  &--left {
    margin-right: auto; }

  &--right {
    margin-left: auto; }

  &:not(:last-child) {
    margin-bottom: 200px;
    @include sm {
      margin-bottom: 57px; }
    @include xs {
      margin-bottom: 46px; } } }
