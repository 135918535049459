.container {
  max-width: 1186px;
  margin: 0 auto;
  padding: 0 36px; }

.show-phone {
  display: none;
  @include sm {
    display: block; } }

.hide-phone {
  @include sm {
    display: none; } }
