.phone {
  $this: &;

  position: relative;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.16);
  width: 343px;
  border-radius: 48px;
  border-style: solid;
  border-width: 15px;
  font-size: 0;
  overflow: hidden;

  @include md {
    width: 280px; }

  @include sm {
    width: 250px;
    margin: 0 auto;
    border-radius: 38px;
    border-width: 12px; }

  @include xs {
    width: 214px;
    border-width: 9px;
    border-radius: 32px; }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -83px;
    width: 167px;
    height: 24px;
    border-radius: 0 0 68px 68px;

    @include md {
      width: 130px;
      margin-left: -65px; }
    @include sm {
      height: 17px;
      width: 122px;
      margin-left: -61px; }

    @include xs {
      height: 15px;
      width: 104px;
      margin-left: -52px; } }

  img {
    max-width: 100%;
    height: auto; }

  &--white {
    border-color: #fff;
    &:before {
      background-color: #fff; } }
  &--black {
    border-color: black;
    &:before {
      background-color: black; } } }
