.socials {
  display: flex;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 50%;

    @include xs {
      width: 26px;
      height: 26px; }
    .icon {
      transition: .3s ease;

      @include xs {
        font-size: 12px; } }

    &:not(:last-child) {
      margin-right: 20px;

      @include xs {
        margin-right: 6px; } }

    &:hover {
      background-color: #645AD7;
      transition: .3s ease;
      .icon {
        fill: #fff; } } }

  &--primary {
    a {
      background-color: black;
      .icon {
        fill: white; } } }

  &--secondary {
    a {
      background-color: #393939;

      .icon {
        fill: black; }
      @include sm {
        &:not(:last-child) {
          margin-right: 11px; } } } }

  &--white {
    a .icon {
      fill: #fff; } } }
